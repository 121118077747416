.about-main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    animation-name: fadeIn;
    animation-duration: 1s;
}

@media (max-width: 450px) {
    .bio {
        grid-row: 2 / 4!important;
        grid-column: 1 / 4!important;
        margin: 0.5em!important;
        height: 70%!important;
        width: 90%!important;
    }

    .bio-img {
        grid-column: 1!important;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0px!important;
        height: 250px!important;
    }

    .movelist {
        justify-self: left!important;
        height: 138px!important;
    }

    .about-me {
        margin-left: 1em;
        width: 95%!important;
        font-size: 12px;
    }

    .git, .in {
        width: 20px!important;
    }

    .link {
        color: black;
        font-size: 10pt!important;
        padding: 2px!important;
    }
    .links {
        display: flex;
        margin: auto;
        grid-column: 1 / 4!important;
    }
    .link-info {
        display: flex;
    }

    .back-home-about {
        font-size: 14px!important;  
        padding-top: 1em; 
    }
}

.link {
    color: black;
    font-size: 24pt;
    padding: 0.5em;
}

.bio {
    display: grid;
    width: 70%;
    height: 100%;
    margin-top: 5em;
    margin-left: 2em;

    justify-self: center;
    grid-template-columns: 25% 75% ;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    color: black;
    background-color: white;
    /* border: 2px solid white; */
    border-radius: 10px;
  
    animation-name: fadeInLeft;
    animation-duration: 1s;
}

.bio-img {
    /* padding: 1em; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 100%;
}
  
.more-info {
    display: grid;
    /* width: 95%; */
    grid-template-columns: 1fr 1fr ;
    grid-template-rows: 20% 1fr;
    /* padding-left: 2em; */
}
  
.movelist {
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 3;
    height: 75%;
}

.about-me {
    grid-column: 1 / 3;
    font-family: 'Roboto';
}

.char-name {
    z-index: 2;
    color: red;
    font-size: 5em;
    /* font-family: 'Ssf4Abuket-7AVD'; */
}

.git, .in {
    width: 50px;
}

.back-home-about {
    z-index: 2;
    grid-column: 1 / 4;
    font-size: 2vw;
    justify-self: start;
    grid-row-start: 1;
    height: 25%;
    /* padding-top: 2%;
    padding-bottom: 2%; */
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Ssf4Abuket-7AVD';
}

.back-home-about:hover {
    /* background: linear-gradient(to right, red , rgba(255, 255, 255, 0)); */
    background-color: red;
    animation: pulse-red 1s infinite;
}

.links {
    display: flex;
}
.link-info {
    display: flex;
}



@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}

@keyframes pulse-red {
	0% {
    background-color: rgba(255, 0, 0, 0);
  }

	50% {
    background-color: red;
  }

  100% {
    background-color: rgba(255, 0, 0, 0);
	}
}