.skills-main {
    display: grid;
    text-align: center;
    grid-template-columns: 1fr 50% 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
    height: 100vh;
    background-size: cover;
    animation-name: fadeIn;
    animation-duration: 1s;
}

@media (max-width: 450px) {
    .icons {
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .icon-holder {
        margin: 0.1em!important;
    }
    .skill-description {
        font-size: 16px!important;
        width: 350px!important;
    }

    .back-home-skills {
        font-size: 14px!important;  
        padding-top: 1em; 
    }

}

.skill-description {
    text-align: center;
    grid-column: 1 / 4;
    grid-row: 3;
    width: 50%;
    /* margin-top: 2%;
    padding-left: 10%;
    padding-top: 4%;
    padding-bottom: 4%; */
    justify-self: center;
    color: white;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.712) , rgba(255, 255, 255, 0));
    font-size: 3vh;
    font-family: 'Roboto';
}

.icons { 
    /* align-self: center; */
    text-align: center;
    display: flex;
    color: white;
    justify-self: center;
    grid-column: 1 / 4;
    grid-row-start: 2;
    /* width: 90%; */
    padding: 2%;
    margin: 2em;
}
.icon-holder {
    margin: 1em;
    text-align: center;
}

.fab {
    text-align: center;
    font-size: 4vw;
    margin: 1%;
    width: 1em;
    padding: 0.25em;
    border: 2px solid rgb(19, 57, 141);
    border-radius: 25%;
}
  
.fab:hover {
    border: 2px solid rgb(19, 57, 141);
    animation: pulse-blue-2 1s infinite;
}

.back-home-skills {
    z-index: 2;
    grid-column: 1 / 4;
    font-size: 2vw;
    justify-self: start;
    grid-row-start: 1;
    height: 25%;
    /* padding-top: 2%;
    padding-bottom: 2%; */
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Ssf4Abuket-7AVD';
}

.back-home-skills:hover {
    /* background: linear-gradient(to right, red , rgba(255, 255, 255, 0)); */
    background-color: red;
    animation: pulse-red 1s infinite;
}


@keyframes pulse-red {
	0% {
    background-color: rgba(255, 0, 0, 0);
  }

	50% {
    background-color: red;
  }

  100% {
    background-color: rgba(255, 0, 0, 0);
	}
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}