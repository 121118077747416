.home-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    height: 100vh;
    background-image: url('../Assets/mainmenu.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    animation-name: fadeIn;
    animation-duration: 1s;
}
@media (max-width: 450px) {
  .description {
    font-size: 5vw!important;
  }
  .my-name {
    font-size: 5vw!important;
  }
  .my-description {
    font-size: 2.5vw!important;
    margin-top: 30%!important;
  }
}

.my-name {
    color: white;
    font-size: 4vw;
    font-family: 'Ssf4Abuket-7AVD';
    grid-column: 2;
    grid-row: 1;
    height: 30%;
    justify-self: right;
    padding-top: 3%;
    /* padding-right: 3%; */
    /* background-image: linear-gradient(to left, rgba(255, 0, 0, 0.76) , rgba(255, 255, 255, 0)); */
    animation-name: fadeInRight;
    animation-duration: 1s;
}

.my-description {
    color: white;
    font-size: 1.5vw;
    text-shadow: 0px 0px 10px #FF0000;
    font-family: 'Ssf4Abuket-7AVD';
    grid-column: 2;
    grid-row: 1;
    justify-self: right;
    padding-right: 5%;
    margin-top: 25%;
    animation-name: fadeInRight;
    animation-duration: 1s;
}

.description {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    padding-left: 10%;
    color: white;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.712) , rgba(255, 255, 255, 0));
    text-shadow: 2px 2px #000000;
    font-size: 2vw;
    animation-name: fadeInUp;
    animation-duration: 1s;
    font-family: 'Roboto';
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    100% {
      opacity: 1;
      transform: none;
    }
  }