.project-main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
    height: 100vh;
    background-image: url('../Assets/background.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    animation-name: fadeIn;
    animation-duration: 1s;
    overflow: hidden;
}

@media (max-width: 450px) {
    .project-main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    .char-portrait {
        grid-column: 1 / 3!important;
        grid-row: 1 / 3!important;
        /* justify-self: center!important; */
        margin-left: 2em;
        height: 50vh!important;
    }
    
    .project-container {
        width: 80%!important;
        margin-right: 0.5em!important;
        margin-top: 1em!important;
        margin-bottom: 2em!important;
        grid-column: 2!important;
        grid-row: 2!important;
        align-self: end!important;
        justify-self: right!important;
    }

    .project-title {
        margin-right: 1em!important;
        justify-self: right!important;
        font-size: 16px!important;
        grid-column: 2!important;
        grid-row: 2!important;
    }

    .projects-select {
        /* z-index: 1!important;   */
        padding-left: 1em;
        font-size: 20px!important;
        background-image: linear-gradient(to right, rgba(255, 0, 0, 0.596) , rgba(255, 255, 255, 0))!important;
        justify-self: left!important;
        align-self: end!important;
        grid-column: 1 / 3!important;
        grid-row: 1 / 3!important;
        color: white;
    }
    
    .character {
        width: 35px!important;
    }

    .project-video {
        z-index: 1;  
        grid-column: 1 / 3!important;
        grid-row: 3 / 5!important;
        margin-bottom: 0em!important;
        width: 350px!important;
        justify-self: center!important;
        align-self: start!important;
    }

    .video-preview {
        width: 350px!important;
        height: 175px!important;
    }

    .repo, .live {
        font-size: 12pt;
        margin: 0.5em!important;
        padding: 0.5em!important;
    }

    .back-home-projects {
        font-size: 14px!important;  
        padding-top: 1em; 
    }

    .spinner-div {
        margin: auto;
        grid-column: 1 / 3!important;
        grid-row: 1 / 3!important;
    }
}

.projects-select {
    padding-right: 5%;
    z-index: 2;
    background-image: linear-gradient(to left, rgba(255, 0, 0, 0.596) , rgba(255, 255, 255, 0));
    justify-self: right;
    align-self: center;
    grid-column: 1 / 4;
    grid-row: 1;
    color: white;
    font-size: 4vw;
    font-family: 'Ssf4Abuket-7AVD';
}

.project-title {
    /* margin-left: 2em; */
    /* padding: 2em; */
    z-index: 3;
    color: white;
    font-size: 3vw;
    font-family: 'Ssf4Abuket-7AVD';
    align-self: center;
    justify-self: left;
    grid-column: 2 / 4;
    grid-row: 2 / 4;
}

.project-container {
    margin-top: 10em;
    /* position: fixed; */
    /* align-self: end; */
    /* padding: 2em; */
    justify-self: left;
    align-self: center;
    z-index: 3;
    grid-column: 2 / 4;
    grid-row: 2 / 4;
    /* justify-self: center; */
    width: 40%;
}

.character {
    z-index: 1;
    margin: 1%;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50%;
    width: 5vw;
}

.character:hover {
    border: 2px solid rgb(19, 57, 141);
    animation: pulse-blue 1s infinite;
}
  

.char-portrait {
    z-index: 0;
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    align-self: end;
    height: 90vh;
    /* width: 200px; */
    animation-name: fadeInRight;
    animation-duration: 0.75s;
}

.project-video {
    z-index: 1;  
    grid-column: 2 / 4;
    grid-row: 1 / 4;
    margin-bottom: 7em;
    /* margin: 2em; */
    width: 60%;
    justify-self: end;
    align-self: end;
    animation-name: fadeInUp;
    animation-duration: 0.75s;
    /* border: 2px solid yellow;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 6px red; */
}

.repo, .live {
    font-size: 12pt;
    margin: 1em;
    padding: 1em;
    border: white;
    border-radius: 5px;
}
  
video {
    border-radius: 10px;
}

.caption {
    text-align: center;
    color: white;
    padding: 2%;
    background-color: rgba(255, 0, 0, 0.596);
    border-radius: 10px;
    font-family: 'Roboto';
}
  
.video-preview {
    display: flex;
    width: 100%;
    height: 70%;
    flex-direction: column;
    margin: auto;
}

.back-home-projects {
    z-index: 2;
    grid-column: 1 / 4;
    font-size: 2vw;
    justify-self: start;
    grid-row-start: 1;
    height: 25%;
    /* padding-top: 2%;
    padding-bottom: 2%; */
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Ssf4Abuket-7AVD';
}

.back-home-projects:hover {
    /* background: linear-gradient(to right, red , rgba(255, 255, 255, 0)); */
    background-color: red;
    animation: pulse-red 1s infinite;
}



@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    100% {
      opacity: 1;
      transform: none;
    }
}

@keyframes pulse-red {
	0% {
    background-color: rgba(255, 0, 0, 0);
  }

	50% {
    background-color: red;
  }

  100% {
    background-color: rgba(255, 0, 0, 0);
	}
}

@keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    100% {
      opacity: 1;
      transform: none;
    }
  }

.spinner {
    background: transparent !important;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 5px solid;
    border-color: #5499D3;
    border-bottom-color: transparent;
    display: inline-block;
    animation: spin 0.75s 0s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }
  
    100% {
        transform: rotate(360deg);
    }
  }